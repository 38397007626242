import { React, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import FeaturesContent from '../components/FeaturesContent';
import PhotoSlider from '../components/PhotoSlider';
import Card from '../components/Card';
import Landscape from '../assets/images/landscape.webp'
import Integration from '../vectors/Integration';
import timesheet from '../assets/images/timesheets.png';
import site from '../assets/images/site.png';
import dashboard from '../assets/images/dashboard.png';
import projectList from '../assets/images/project-list.png';
import projectDetails from '../assets/images/project-details.png';


const TimeTracking = () => {

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const timesheetData = [
        {
            image: timesheet,
            alt: 'Timesheets example'
        }
    ];
    const projects = [
        {
            image: projectList,
            alt: 'Project list'
        },
        {
            image: projectDetails,
            alt: 'Project details'
        }
    ];

    return (
        <FeaturesContent title="Time and attendace management" icon={faClock}>
            <div className="flex justify-between flex-wrap">
                <div className="text-xl py-5 w-2/5 md:py-10">
                    <p className="my-8 font-light">
                        In today's modern age, businesses need an efficient time tracking solution to
                        minimize labor costs and ensure compliance with Labor Law and specific regulations
                        (overtime, part-time work, minimum wage, etc.).
                        Outdated methods like physical time cards or rough estimates are no longer effective.
                        This is why companies of all sizes are upgrading to modern employee time tracking
                        systems, with <strong>Punch-in</strong> being a flexible and comprehensive option.
                    </p>
                    <PhotoSlider data={timesheetData} settings={sliderSettings} />

                    <p className="my-8 font-light">
                        When you refer others to <strong>Punch-in</strong>, they gain the following benefits:
                    </p>

                    <div class="max-w-lg mx-auto bg-white shadow-md rounded-lg p-6">
                        <h2 class="text-xl font-semibold mb-4 text-gray-700">Benefits of Punch-in:</h2>
                        <ul class="list-disc list-inside space-y-4">
                            <li class="flex items-start">
                                <span class="flex-shrink-0 inline-block w-6 h-6 bg-green-500 rounded-full text-white font-bold text-center mr-4">1</span>
                                <span class="text-gray-600">Increased accountability for their team</span>
                            </li>
                            <li class="flex items-start">
                                <span class="flex-shrink-0 inline-block w-6 h-6 bg-blue-500 rounded-full text-white font-bold text-center mr-4">2</span>
                                <span class="text-gray-600">The ability to streamline payroll and generate reports</span>
                            </li>
                            <li class="flex items-start">
                                <span class="flex-shrink-0 inline-block w-6 h-6 bg-red-500 rounded-full text-white font-bold text-center mr-4">3</span>
                                <span class="text-gray-600">An intuitive time tracking software with simplicity at its core</span>
                            </li>
                        </ul>
                    </div>

                    <p className="my-8 font-light">
                        <strong>Punch-in</strong> goes beyond being a basic time tracking mobile app. It offers a full-service solution for all employee scheduling needs. Here’s a brief look at how <strong>Punch-in</strong> enhances accountability, streamlines operations, and simplifies time tracking for business owners:
                    </p>

                </div>
                <div className='w-2/5 ml-4 mt-8'>
                    <Card>
                    <h2 class="text-xl font-semibold mb-4 text-gray-700">Features</h2>
                        <p className="my-8 font-light">
                            Location autodetection
                            <img src={site} alt="Site configuration" className='w-full' />
                        </p>
                        <p className="my-8 font-light">
                            Project and task-related timesheets
                        </p>
                        <p className="my-8 font-light">
                            Dashboard
                            <img src={dashboard} alt="Dashboard" className='w-full' />
                        </p>
                        <p className="my-8 font-light">
                            Project management
                            <PhotoSlider data={projects} settings={sliderSettings} />
                        </p>
                    </Card>
                </div>
            </div>
        </FeaturesContent>
    )
}

export default TimeTracking;